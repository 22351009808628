import React, { useContext } from 'react';
import PortableText from 'react-portable-text';
import { BgImage } from 'gbimage-bridge';

import { makeStyles } from '@material-ui/core/styles';
import {
	Grid,
	Typography,
	Container,
	TextField,
	InputAdornment,
	SvgIcon,
} from '@material-ui/core';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

import { EmailInput } from '../Home/EmailInput';
import { WaveUpHero } from '../WaveSVGs/WaveUpHero';
import { HeroBody } from '../CustomStyles/Typography';
import IndustrySearchContext from '../../context/IndustrySearchContext';

const useStyles = makeStyles((theme) => ({
	text: {
		color: theme.white,
		lineHeight: '.7',
		fontWeight: 400,
		marginBottom: '1rem',
	},
	header: {
		lineHeight: 1.1,
		[theme.breakpoints.down('xs')]: {
			fontSize: '2.2rem',
		},
	},
	imgCont: {
		padding: '6rem 0',
		maxWidth: '100vw',
		height: '100%',
		overflowX: 'hidden',
		backgroundSize: 'cover',
		background:
			'linear-gradient(0deg, rgba(248, 251, 255, 0.8), rgba(248, 251, 255, 0.8)), linear-gradient(0deg, #F8FBFF, #F8FBFF), #F8FBFF',

		[theme.breakpoints.down('xs')]: {
			padding: '3rem 0',
			backgroundPosition: 'right',
		},
	},
	imgRight: {
		marginLeft: '-10rem',
		[theme.breakpoints.down('lg')]: {
			marginLeft: '-9rem',
			height: '560px',
		},
		// [theme.breakpoints.down('md')]: {
		// 	marginLeft: '-4rem',
		// 	height: '380px',
		// },
		[theme.breakpoints.down('sm')]: {
			marginLeft: '-8rem',
			height: '300px',
		},
		[theme.breakpoints.down('xs')]: {
			marginLeft: '0',
			height: '260px',
		},
	},
	star: {
		marginRight: '.5rem',
		color: '#FFCA43',
	},
	wave: {
		marginTop: '-9rem',
		// [theme.breakpoints.down('lg')]: {
		// 	marginTop: '-9rem',
		// },
		// [theme.breakpoints.down('md')]: {
		// 	marginTop: '-7rem',
		// },
		// [theme.breakpoints.down('sm')]: {
		// 	marginTop: '-7rem',
		// },
		[theme.breakpoints.down('xs')]: {
			marginTop: '-8rem',
		},
	},
	inputLabel: {
		'&.shrink': {
			backgroundColor: 'white',
			padding: '2px',
			borderRadius: '5px',
		},
	},
	cta: {},
	searchIconBackground: {
		height: '56px',
		width: '56px',
		position: 'relative',
		left: '22px',
		background: '#002D5C',
	},
	searchIcon: {
		color: '#fff',
		height: '56px',
		width: '56px',
		paddingTop: '6px',
		background: '#002D5C',
		borderRadius: '0 4px 4px 0',
	},
}));

export const Hero = ({
	hero,
	accentColor,
	allIndustries,
	handleInputScroll,
	industriesData,
}) => {
	const classes = useStyles();
	// const isDesktop = useMediaQuery('(min-width: 960px)');

	const { handleChange, industries } = useContext(IndustrySearchContext);

	return (
		<>
			<BgImage
				className={classes.imgCont}
				image={hero?.backgroundImage?.asset?.gatsbyImageData}
				style={{
					// backgroundImage: `url(${hero?.backgroundImage?.asset?.gatsbyImageData.images.fallback.src})`,

					lineHeight: '0',
					overflow: 'hidden',
				}}>
				<Container fixed>
					<Grid container direction='row' justifyContent='flex-start'>
						<Grid
							container
							item
							direction='row'
							justify='flex-start'
							alignItems='flex-start'
							xs={12}
							md={10}>
							{hero?._rawContent.map((content, index) => (
								<PortableText
									key={index}
									content={content}
									className={classes.text}
									serializers={{
										h1: ({ children }) => (
											<Typography variant='h1' className={classes.header}>
												{children}
											</Typography>
										),
										normal: ({ children }) => (
											<HeroBody industry={true}>{children}</HeroBody>
										),
									}}
								/>
							))}{' '}
						</Grid>
						{hero.ctaText && (
							<Grid item xs={12} md={9}>
								{allIndustries ? (
									<Grid container direction='row' style={{ padding: '2rem 0' }}>
										<TextField
											variant='outlined'
											onKeyUp={(e) =>
												handleChange(e, industries, industriesData)
											}
											fullWidth
											label='SEARCH FOR YOUR INDUSTRY'
											style={{
												borderRadius: '4px',
												background: '#FFF',
												boxShadow:
													'8px 8px 10px rgb(50 50 93 / 15%), 0 2px 5px rgb(0 0 0 / 12%)',
											}}
											InputLabelProps={{
												classes: {
													root: classes.inputLabel,
													shrink: 'shrink',
												},
											}}
											InputProps={{
												type: 'search',
												endAdornment: (
													<InputAdornment
														className={classes.searchIconBackground}>
														{/* <SvgIcon className={classes.searchIcon}>
															<svg
																xmlns='http://www.w3.org/2000/svg'
																height='.7em'
																viewBox='0 0 512 512'
																fill='#FFF'>
																<path d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z' />
															</svg>
														</SvgIcon> */}
														<SearchTwoToneIcon className={classes.searchIcon} />
													</InputAdornment>
												),
											}}
										/>
									</Grid>
								) : (
									<EmailInput
										accentColor={accentColor}
										ctaText={hero.ctaText}
									/>
								)}
							</Grid>
						)}
					</Grid>
				</Container>
				<WaveUpHero height='213' width='100%' fill='white' />
			</BgImage>
		</>
	);
};
