import React, { useRef, useContext } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Hero } from '../components/Industry/Hero';
import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import FormModalContext from '../context/FormModalContext';
import { SEO } from '../components/SEO';
import { CustomerLogos } from '../components/CustomerLogos';
import { AllIndustryBody } from '../components/Industry/AllIndustry/AllIndustryBody';
// import { Form } from '../components/ContactForm/Form';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	formBackground: {
		background: theme.workwaveBlue,
		paddingBottom: '2rem',
		marginTop: '-7rem',
		[theme.breakpoints.down('md')]: {
			marginTop: '-9rem',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-10rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: '-12rem',
		},
	},

	formWaveDown: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-4rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginBottom: '-3rem',
		},
	},
	formWaveUp: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-10rem',
		},
	},
	formCont: {
		padding: '24rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '16rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '8rem 0',
		},
	},
}));

const IndustriesPage = ({ data, location }) => {
	const allIndustries = data.allIndustries.edges[0].node;
	const classes = useStyles();
	const industriesData = data.industries.edges;
	const inputRef = useRef();
	const formRef = useRef(null);

	//stuff for modal and form
	const { formModalOpen, handleModalClick, setFormModalOpen, email } =
		useContext(FormModalContext);

	const handleInputScroll = (e) => {
		e.preventDefault();
		inputRef?.current?.scrollIntoView({
			behavior: 'smooth',
		});
	};

	const med = useMediaQuery('(max-width: 960px)');
	const {
		metaTitle,
		metaDescription,
		industryHero,
		customerLogos,
		customerLogosHeader,
		formBgImage,
		marketoId,
		pardotUrl,
		contactForm,
		resourceTitle,
		resources,
		_rawResourceBody,
	} = allIndustries;
	//Think I want to clean and refactor this into smaller components for readability.

	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<div ref={inputRef}></div>
			<Hero
				hero={industryHero}
				allIndustries={true}
				handleInputScroll={handleInputScroll}
				waveHeight='150'
				industriesData={industriesData}
			/>
			<AllIndustryBody
				allIndustries={allIndustries}
				industriesData={industriesData}
				setFormModalOpen={setFormModalOpen}
			/>
			<Container>
				<CustomerLogos
					customerLogosArray={customerLogos}
					customerLogosHeader={customerLogosHeader}
				/>
			</Container>
			<WaveDownSVG fill='#FFFFFF' />

			<div
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}
				className={classes.formCont}>
				<Form
					formId={marketoId}
					pardotUrl={pardotUrl}
					contactForm={contactForm}
					privacy
					location={location}
				/>
			</div>
			<WaveUpSVG fill='#FFFFFF' />
			<Container>
				<Resources
					header={resourceTitle}
					resources={resources}
					subheader={_rawResourceBody}
				/>
			</Container>
			<WaveUpSVG fill='#f4f8ff' />
		</>
	);
};

export const query = graphql`
	query IndustriesPageQuery {
		allIndustries: allSanityAllIndustries {
			edges {
				node {
					title
					metaTitle
					metaDescription
					industryHero {
						ctaText
						_rawContent
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					noIndustrySubTitle
					noIndustryTitle
					noIndustryctaText
					testimonialTitle
					testimonial {
						title
						header
						testimonialLogo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						testimonialText
						nameAndTitle
						videoVariant
						company
						image {
							asset {
								gatsbyImageData(
									placeholder: BLURRED
									fit: FILLMAX
									height: 500
									width: 750
								)
							}
						}
					}
					resourceTitle
					_rawResourceBody
					resources {
						title
						blurb
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaText
						ctaLink
					}
					contactForm {
						header
						privacyPolicy
					}
					formBgImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					marketoId
					pardotUrl
					customerLogos {
						logo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
					customerLogosHeader
				}
			}
		}
		industries: allSanityIndustries(sort: { fields: [title], order: ASC }) {
			edges {
				node {
					_id
					title
					slug {
						current
					}
					mosaicType {
						title
					}
					mosaicBackground {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					mosaicPrimary
					tags
					faIcon
					accentColor {
						hexValue
					}
					_rawSynopsis
				}
			}
		}
	}
`;

export default IndustriesPage;
